import React from "react"

import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import Header from "./Header"
import Footer from "./Footer"
import {
  PopinsBlack,
  PopinsThin,
  PopinsSemibold,
  PopinsRegular,
  PopinsMedium,
  PopinsLight,
  PopinsItalic,
  PopinsExtralight,
  PopinsExtrabold,
  PopinsBold,
} from "../styles/fonts"
import SEO from "../components/SEO"

const GlobalStyle = createGlobalStyle`
    ${normalize}

    @font-face{
      font-family: "pblack";
      src: local('Poppins-Black'), url('${PopinsBlack}') format('truetype');
    }
    @font-face{
      font-family: "pthin";
      src: local('Poppins-Thin'), url('${PopinsThin}') format('truetype');
    }
    @font-face{
      font-family: "psemibold";
      src: local('Poppins-Semibold'), url('${PopinsSemibold}') format('truetype');
    }
    @font-face{
      font-family: "pregular";
      src: local('Poppins-Regular'), url('${PopinsRegular}') format('truetype');
    }
    @font-face{
      font-family: "plight";
      src: local('Poppins-Light'), url('${PopinsLight}') format('truetype');
    }
    @font-face{
      font-family: "pitalic";
      src: local('Poppins-Italic'), url('${PopinsItalic}') format('truetype');
    }
    @font-face{
      font-family: "pblack";
      src: local('Poppins-Black'), url('${PopinsBlack}') format('truetype');
    }

    @font-face{
      font-family: "pbold";
      src: local('Poppins-Bold'), url('${PopinsBold}') format('truetype');
    }
       
    @font-face{
      font-family: "pmedium";
      src: local('Poppins-Medium'), url('${PopinsMedium}') format('truetype');
    }

 
    *{
        text-decoration: none;
        box-sizing: border-box;
  
    }

    html{
        background-color: #F7F8F3;
        font-size: 16px;
    }

    body{
        overscroll-behavior: none;
        box-sizing: border-box;
        font-family: "pbold";
    }
`

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <SEO />
      <Header></Header>
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
